.EmployeeCreationformbg {
  background-color: #edd4fa;
  overflow: scroll;
  height: 100vh;
  border-radius: 5px;
}
.EmployeeForm {
  background: #fff6ea;
  margin: 20px;
  border-radius: 5px;
  height: 150vh;
}
.empform {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 75px;
}
.empformleft {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 5px;
  line-height: 1.5;
}
.empsubbutton {
  border-radius: 5px;
  padding: 10px 40px;
  background: #7a1cac;
  color: white;
  border: none;
  outline: none;
  align-items: center;
  display:flex;
  justify-content: center;
}

.empsub {
  font-size: 18px;
  font-weight: 700;
  margin-top: 4px;
  border-radius: 5px;
  outline: none;
  display:flex;
  justify-content: center;
  align-items: center;

}
.inputeid {
  width: 400px;
  height: 50px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fff6ea;
}
.empformright {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 5px;
  line-height: 1.5;
}
.input-id{
  width: 400px;
  height: 50px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fff6ea; 
}
