.formbg {
  background-color: rgb(206, 164, 245);
  height: 100vh;
  padding: 20px;
  overflow: scroll;
}

.quotationFormheader {
  border-radius: 20px;
  /* width: 1130px; */
  height: 180%;
  top: 120px;
  left: 280px;
  gap: 0px;
   /* opacity: 0px; */
  background: #fff6ea;
  border-radius: 9px;
  padding: 30px;
  margin: 20px;
  
}
.quotationcreationform {
  flex-direction: column;
  width: 1130px;
  height: 100%;
  top: 120px;
  left: 280px;
  gap: 0px;
    /* opacity: 0px; */ 
}
.quotationcontainer {
  display: flex;
  flex-direction: row;
  width: 975px;
  height: 878.17px;
  top: 50px;
  left: 50px;
  gap: 175px;
   /* opacity: 0px; */
}
.quotationcreationheader {
  flex-direction: column;
  width: 400px;
  height: 743px;
  gap: 20px;
   /* opacity: 0px; */
  display: flex;
}
.custdate {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 400px;
  background-color: #fff6ea;
  border-radius: 9px;
  outline: none;
}
.quotationinput {
  height: 17px;
  gap: 20px;
   /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  /* height: 50px; */
  padding: 24px 10px;
  width: 400px;
  background: #fff6ea;
  border-radius: 9px;
  outline: none;
}

.quotationlabel {
  width: 400px;
  height: 17px;
  gap: 20px;
   /* opacity: 0px; */

  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.custominput {
  width: 400px;
  height: 97px;
  top: 0.33px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  border: 1px 0px 0px 0px;
   /* opacity: 0px; */
  background: #fff6ea;

  border-radius: 9px;
  outline: none;
}

.quotationcreationheaderright {
  width: 400px;
  height: 878.17px;

   /* opacity: 0px; */
  flex-direction: column;

  gap: 20px;

  display: flex;
}

.btn-group.dropend {
  width: 200px;
  position: relative;
  
  margin-left:-400px;
  margin-bottom: 20px;
  
}

.btn-custom {
  left: 200px;

  width: 100px;
  height: 55px;
  gap: 0px;
   /* opacity: 0px; */
  background-color: #7a1cac !important;
}
.quotationinputselect {
  gap: 20px;
   /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  /* height: 50px; */
  padding: 14px 10px;
  width: 400px;
  background: #fff6ea;
  border-radius: 9px;

  border: 2px solid #000;
}
.quotationlabelinput {
  width: 400px;
  height: 17px;
  gap: 15px;
   /* opacity: 0px; */

  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.quotationinputfile {
  border: 2px solid black;
  width: 400px;
  padding: 10px 10px;
  /* height: 50px; */
  gap: 0px;
  border-radius: 9px;
  border: 1px 0px 0px 0px;
   /* opacity: 0px; */

  top: 16px;
  left: 10px;
  /* padding: 5px 50px 5px 15px; */
  gap: 10px;
}
.quotationdescription {
  width: 400px;
  padding: 14px 10px;
  /* height: 100px; */
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  border: 1px 0px 0px 0px;
   /* opacity: 0px; */
  background: #fff6ea;
  top: 10px;
  border-radius: 9px;
  outline: none;
}
.btn-grad {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
}
.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
