.student {
  height: 100%;
  border-radius: 10px;

  background-color: #edd4fa;
  padding-top: 20px;
}
.stuform {
  background-color: #fff6ea;
  height: 100%;
  margin-right: 10px;
  margin-left: 35px;
  margin-top: 10px;
}
.stulabel {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  gap: 10px;
}
.stuinput {
  width: 400px;
  height: 50px;
  gap: 10px;
  border-radius: 5px;
  border: 1px;
  background-color: #fff6ea;
  border: 1px solid #000000;
}
.stusubmit {
  /* margin-left: 150px; */
  padding-right: 15px;
  margin: 10px;
  width: 200px;
  position: relative;
}
.stsubmit {
  width: 150px;
  height: 55px;
  border-radius: 5px;
  background: #7a1cac;
  color: white;
}
/* .submitbutton{
    padding-bottom:10px;
    padding-left: 100px;
    margin-bottom: 10px;
} */
.student-details {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7A1CAC;

}
.submitbutton{
  display: flex;
  justify-content: center;
  align-items: center;
}