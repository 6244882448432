/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* Modal Content */
.modal-content {
  background-color: #fff6ea !important;
  padding: 20px;
  border-radius: 3px !important;
  position: relative;
  max-width: 40%;
  height: auto;
  overflow: auto;
  scrollbar-width: thin;
}
.popup-head {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.close-modal-btn {
  width: 30% !important;
  height: 40px;
  padding: 10px 50px 10px 50px;
  border-radius: 5px;
  background: #7a1cac;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
  border: none;
  outline: none;
  color: #fff6ea;
}
.close-modal-btn:hover{
  background: #5c1681; 
}