.Uploadpayslipbg {
  background-color: rgb(254, 178, 254);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Uploadform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.uploadtext {
  width: 400px;
  height: 17px;
  left: 10px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;

  color: #7a1cac;
  /* padding-Right: 230px; */

  /* padding-Right: 230px; */
  /* background: #7A1CAC; */
}
.uploadinput {
  width: 400px;
  height: 60px;
  top: 27px;
  gap: 0px;
  /* border-radius: 5px 0px 0px 0px; */
  border: 2.5px 0px 0px 0px;
  /* opacity: 0px; */
  border: 2.5px solid #fff6ea;
  border: 2px solid #7a1cac;
  padding: 10px 10px;
  border-radius: 5px;
  background: #fff;
}
.uploadbutton {
  width: 150px;
  height: 55px;
  top: 112px;
  left: 125px;
  gap: 10px;
  /* opacity: 0px; */
  background-color: #7a1cac;
  margin: 20px;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: center;
  /* background: #FFF6EA; */
}
