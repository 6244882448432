.AddSalesbg {
  background-color: #3e065c;
  height: 120%;
}
.AddSalesheader {
  background-color: #e771e7;
  /* width: 300px; */
  margin: 10px;
}
.AddSale {
  width: Hug (1, 130px) px;
  height: Fixed (1, 248px) px;
  top: 120px;
  left: 280px;
  padding: 10px 0px 0px 0px;
  gap: 10px;
  border-radius: 20px 0px 0px 0p;
  /* opacity: 0px; */
  background: rgb(196, 114, 241);
}

.cus-id {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
}
.cus-name {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
}
.cus-flatno {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
}
.cus-area {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;     */
}
.cus-pincode {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
}
.cus-date {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;  */
}
.cus-despt {
  width: 400px;
  height: 90px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
}
.cus-cgst {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;     */
}
.cus-invoice {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;     */
}
.cus-gender {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;       */
}
.company {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.street {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.city {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.state {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;  */
}
.date {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.amt {
  width: 400px;
  height: 50px;
  gap: 80px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.sgst {
  width: 400px;
  height: 50px;
  gap: 80px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.mode {
  width: 400px;
  height: 50px;
  gap: 0px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px;      */
}
.salebutton {
  width: 400px;
}
.salesstyle {
  gap: 50px;
  position: absolute;
  margin: 50px;
  width: 150px;
  height: 55px;
  background: rgb(122, 28, 172);
  right: 80px;
}
.form style {
  width: Hug (1, 130px) px;
  height: Fixed (1, 248px) px;
  top: 120px;
  left: 280px;
  padding: 10px 0px 0px 0px;
  gap: 10px;
  border-radius: 20px 0px 0px 0px;
  /* opacity: 0px; */
}
.background {
  padding-bottom: 200px;
  background: rgba(255, 246, 234, 1);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 30px;
}
.sheet {
  display: flex;
  justify-content: space-between;
  margin-right: 80px;
  margin-left: 80px;
}
.margineright {
  display: flex;
  flex-direction: column;
  width: Fixed (400px) px;
}
.margineleft {
  display: flex;
  flex-direction: column;
}
.scroll {
  overflow: scroll;
}
