.overal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: auto;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
}
h1 {
  align-self: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #7a1cac;
}

.logo-container {
  width: 90%;
  height: 90%;
  /* display:flex;
    justify-content: center;
    align-items: center;
    */
}
.form-container {
  background-color: #ebd3f8;
  width: 450px;
  padding: 20px;
  margin: 30px;
  gap: 15px;
}
.formcontainer {
  gap: 15px;
  margin: 15px;
  display: flex;
  flex-direction: column;
}
.title {
  align-self: center;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #7a1cac;
  margin-top: 2px;
}
.email-container {
  border: 1.5px solid #ccc;
  border-radius: 5px;
  background-color: #ffff;
  display: flex;
  padding: 0px 5px;
}
.pass-container {
  border: 1.5px solid #ccc;
  border-radius: 5px;
  background-color: #ffff;
  display: flex;
  padding: 0px 5px;
}
.mail {
  width: 100%;
  height: 40px;
  /* justify-content: space-between; */
  border: none;
  outline: none;
 
  /* top: 85px;
  gap: 15px; */

}
.login-input-field {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}
.tick {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.check {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  height: 26px;
  font-style: normal;
  font-size: 18px;
  align-items: center;
  color: #777777;
}
.pass {
  width: 100%;
  height: 40px;
  /* justify-content: space-between;  */
  border: 1.5px solid #ccc;
  /* border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column; */
  top: 85px;
  gap: 15px;
  /* opacity: 0px; */
  border: none;
  outline: none;
}
.check-box {
  margin-right: 5px;
}
.login-button {
  background-color: #7a1cac;
  color: white;
  border: none;
  border-radius: 5px;
  /* padding: 7px 20px; */
  height: 38px;
  width: 25%;
  cursor: pointer;
}

.email-container {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px;  */
}

.email-icon-container {
  margin-right: 10px;
  /* width: 20px;  */
  /* height: 20px;  */
}
.input-container {
  flex: 1;
}
.emailicon {
  position: relative;
}
.pass-icon {
  position: relative;
}
