.sidecontainer {
  width: 100%;
  height: 580px;

}
.sideincontainer {
  background-color: rgb(206, 164, 245);
}
.--borderactive {
  border-right: none !important;
  background-color: #7a1cac;
}
.sidelistitem {
  width: 100%;
  /* width: 250px; */
  height: 41px;
  padding: 0px;
  gap: 5px;

  opacity: 1px;

  border-right: 3px solid #7a1cac;

  /* location.pathname === "/viewemployee" ? "" : ; */
  background-color: #fff6ea;

  margin-bottom: 5px;
  box-sizing: border-box;
  position: relative;
}
.nav-link {
  display: flex;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  padding: 0px 25px;
  position: relative;
  border-right: 1px solid transparent;
}
.nav-link-active {
  border-right: none !important;
  background-color: #7a1cac !important;
  color: white !important;
}
.nav-link-inactive {
  border-right: 1px solid transparent;
  background-color: transparent !important;
  color: black !important;
}
