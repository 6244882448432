.Downloadpayslipbg {
  background-color: rgb(249, 157, 249);
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: Fixed (400px) px;
  height: Hug (393px) px;
  top: 315.5px;
  left: 645px;
  gap: 25px;
   /* opacity: 0px; */
}
.Downloadform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 90px;
}
.Form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 50px;
  margin-left: 120px;
}
.Emplabel {
  display: flex;
  flex-direction: column;
  height: 20px;
  width: 200px;
}
.downbutton {
  /* display: flex; */
  /* flex-direction: column;
    align-items: center;
    align-self: center;
    align-content: center;
    /* padding-top:350px; */
  /* padding: 350px; */
  /* padding-right: 200px; */
  /* padding-right: 100px; */
  /* padding-bottom: 50px; */
  /* margin: 30px;
    display: block; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}
.downfirst {
  width: 400px;
  height: 87px;
  gap: 0px;
   /* opacity: 0px; */
}
.downlabel {
  /* width: 84px; */
  height: 17px;
  left: 10px;
  gap: 0px;
   /* opacity: 0px; */
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #7a1cac;
  width: "32px";
}
.downinput {
  width: 400px;
  height: 60px;
  top: 27px;
  gap: 0px;
  border-radius: 5px;
  border: 2.5px 0px 0px 0px;
   /* opacity: 0px; */
  border: 2.5px solid #7a1cac;
}
.downtext {
  width: 287px;
  height: 12px;
  gap: 0px;
   /* opacity: 0px; */
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  color: #7a1cac;
  margin-left: 100px;
}
.downform {
  /* width: Fixed (400px)px; */
  /* height: Hug (393px)px; */
  /* top: 315.5px; */
  /* left: 645px; */
  gap: 25px;
   /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* align-self: center; */
  /* margin-bottom: 90px; */
}
.downbuttext {
  width: 150px;
  height: 55px;
  gap: 0px;
   /* opacity: 0px; */
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: center;
  background: #7a1cac;
  color: white;
  border-radius: 5px;
}
/* .pass-icon{
position:relative
} */
