/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #144dc0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(118, 10, 145);
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.empl{
  font-size: 14px; 
color: black;
font-weight: 500;
}
 */

.loginMainSection {
  width: 100%;
  height: 100vh;
}
.login-main-container {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.login-heading {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 38.57px;
  text-align: center;
  color: #7a1cac;
}
.login-logo-block {
  width: 110px;
  height: 110px;
}
.image_icon {
  width: 100%;
  height: 100%;
}
.login_input_field {
  width: 100%;
  background: #ffffff;
  border: none;
  font-family: Inter;
  font-size: 16px;
  padding: 0px 8px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  color: #afafaf;
  outline: none;
}
.login-input-block {
  display: flex;
  margin: 14px 0px;
  align-items: center;
  border-radius: 5px;
  background: #ffffff;
  border: 1.5px solid #cccccc;
  padding: 0px 8px;
  height: 45px;
  width: 100%;
}
.email-image {
  width: 25px;
  height: 25px;
}
.login-form-container {
  width: 40%;
  height: auto;
  padding: 30px;
  background: #ebd3f8;
}
.login-btn-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
}
.downloadNotationMainSection {
  width: 100%;
  height: calc(100vh - 55px);
  overflow: auto;
  scrollbar-width: none;
  /* background-color: #fff6ea; */
  background: #ebd3f8;
}
#sheet1 {
  /* height: 160vh; */
  /* 2,480 x 3,508 */
  /* height: 2480px;
  width:3508px; */
  /* 595 x 842 */
  width: 842px;
  /* height: 595px; */
}
#sheet2 {
  height: 160vh;
  width: 842px;
}
#sheet3 {
  height: 160vh;
  width: 842px;
}
.quotaionMainSection {
  /* height: calc(100vh - 70px); */
  /* height: 3508px;
  width: 2480px; */

  /* height: auto; */
  margin: 10px auto;
  width: 210mm;
  position: relative;
  height: 297mm;
  /* width: 95%; */
  background-color: white;
  overflow: auto;
  scrollbar-width: none;
}

.download-quotation-head {
  font-size: 34px;
  color: red;
  font-weight: 700;
  line-height: normal;
  font-family: Inter;
}
.download-quotation-title {
  font-size: 18px;
  margin: 3px 0px;
  font-weight: 400;
  line-height: normal;
  font-family: Inter;
  color: rgb(69, 101, 172);
}
.quotation-text {
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  font-family: Inter;
  display: inline-block;
  /* width: 15%; */
  color: black;
}
.quotation-text-value {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  font-family: Inter;
  display: inline-block;
  /* width: 10%; */
  text-align: start;
}
.quotation-text-head {
  margin: 1px 0px;
  width: 100%;
}
.quotation-text-content {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  font-family: Inter;
  text-indent: 18px;
}
.quotation-text-para {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  font-family: Inter;
  /* text-indent: 18px;  */
}
.download-notation-head {
  background-color: brown;
  color: white;
  font-weight: 900;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  padding: 15px;
}
.download-table-block {
  width: 90%;
  margin: 0px auto;
  border-collapse: collapse;
}
.download-table-block th,
.download-table-block td {
  border: 1px solid rgba(0, 0, 0, 0.753);
}
.download-notation-table-data {
  font-weight: 400;
  text-align: center;
  font-family: Inter;
  padding: 10px;
  font-size: 14px;
}
.download-notation-sub-heading {
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
  display: inline-block;
  border-bottom: 1px solid black;
}
.quotaion-tq-text {
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
  font-style: italic;
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: left;
  justify-content: start;
}
.quote-details {
  width: 300px;
  margin: 20px auto;
  padding: 10px;
  /* border: 1px solid #ccc; */
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}
.detail {
  display: flex;
  /* justify-content: space-between; */
  width: 30%;
  padding: 2px 0;
}

.label {
  font-weight: bold;
  width: 120px;
  text-align: left;
  /* width: 120px; Adjust as needed */
}
.value_dd {
  text-align: left;
}
.--url {
  position: absolute;
  bottom: 20px;
}
